import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { services } from '../../data';
import { Button } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '60%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
};

const ServiceModal = ({ open, handleClose, serviceID }) => {
    const data = services.find(item => item.id === serviceID) || {}

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography>
                    <img src={data.image} width={'100%'} />
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {data.name}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {data.description}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {data.callToAction}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <Button disableElevation variant='contained' fullWidth component='a' href='https://wa.me/qr/O6U6CP3K6KMDP1' >Reserver</Button>
                </Typography>
            </Box>
        </Modal>
    )
}

export default ServiceModal