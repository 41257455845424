import React from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import Header from "./header";
import Footer from "./footer";
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

const Layout = ({ children }) => {
  const theme = useTheme()
  return (
    <main>
      <Header />
      <Box>{children}</Box>
      <Footer />
      <IconButton component='a' href="https://wa.me/qr/O6U6CP3K6KMDP1" sx={{ position: 'fixed', bottom: 10, right: 10, backgroundColor: theme.palette.primary.main }}>
        <WhatsAppIcon fontSize={'large'} />
      </IconButton>
    </main>
  );
};

export default Layout;
