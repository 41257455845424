import { services } from "../../data";

export const menuList = [
  {
    name: "A propos",
    href: "/a-propos",
  },

  {
    name: "Nos services",
    href: "/nos-services",
    items: [
      ...services
    ],
  },

  {
    name: "Tarifs",
    href: "/tarif",
  },
  {
    name: "Galerie",
    href: "/galerie",
  },
  {
    name: "Contact",
    href: "/contact",
  },
];

export const bottomLinks = [
  {
    name: "Faire un don",
    href: "/",
  },
  {
    name: "Témoignages",
    href: "/",
  },
  {
    name: "Publications",
    href: "/",
  },
  {
    name: "Evénements",
    href: "/",
  },
  {
    name: "A propos",
    href: "/",
  },
  {
    name: "Contact",
    href: "/",
  },
];
