import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import NavItem from "./NavItem";
import { useScrollTrigger, useTheme } from "@mui/material";
import { menuList } from "../menuList";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";


const Topbar = ({ onSidebarOpen }) => {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const [activeLink, setActiveLink] = React.useState("");
  React.useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: { md: "black", xs: "black" },
        color: theme.palette.primary.main,
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ ml: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={"outlined"}
            >
              <MenuIcon
                sx={{ color: { md: theme.palette.primary.main, xs: "white" } }}
              />
            </IconButton>
          </Box>

          <Typography
            component="a"
            sx={{ mr: 0.5, mt: 1, textAlign: "center", flexGrow: 1 }}
            href={"/"}
          >
            <img
              src={"/logo.png"}
              style={{ borderRadius: 50 }}
              alt="logo"
              width={130}
            />
          </Typography>
          <Box
            sx={{
              ml: 2,
              flexGrow: 1,
              justifyContent: "flex-start",
              display: { xs: "none", md: "flex" },
            }}
          >
            {menuList.map((page) =>
              page.items ? (
                <Box sx={{ m: 3 }}>
                  <NavItem title={page.name} items={page.items} />
                </Box>
              ) : (
                <Button
                  component="a"
                  href={'/'}
                  sx={{
                    color:
                      activeLink === page.href
                        ? theme.palette.primary.main
                        : "white",
                    fontSize: 13,
                    m: 1,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {page.name.toUpperCase()}
                </Button>
              )
            )}
          </Box>
          <Button
            component="a"
            startIcon={<WhatsAppIcon />}
            href=" https://wa.me/qr/O6U6CP3K6KMDP1"
            size="small"
            target="_blank"
            variant="contained"
            sx={{
              textTransform: "uppercase",
              display: { xs: "none", md: "flex" },
            }}
            color="primary"
          >
            Nous contacter
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Topbar;
