import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import React from "react";

const HomeBooking = () => {
  return (
    <Container sx={{ p: 5 }}>
      <form>
        <Grid container spacing={2}>
          <Grid sx={{ my: 3 }} item xs={12}>
            <Typography textAlign={"center"} variant="h3">
              RESERVATION
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign={"center"}>
              <img src="/images/epilation.jpg" width={"100%"} />
            </Typography>
          </Grid>
          <Grid direction={'row'} justifyContent={"center"} alignItems={'center'} container spacing={1} item xs={12} md={6}>
            <Grid item xs={12}>
              <Typography variant="h5">
                Réservez dès maintenant un servvice chez P-Twins Beauty Spa et découvrez une nouvelle façon de prendre soin de vous!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button disableElevation fullWidth variant="contained">
                Reserver
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default HomeBooking;
