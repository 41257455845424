import React from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { services } from '../../data'
import Slider from 'react-slick'


const ServiceDetails = () => {
    const params = useParams()
    const data = services.find(item => item.id === params.serviceID)
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1

    }

    return (
        <main>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Slider {...settings}>
                        {data.images.map(image => (
                            <Typography>
                                <img src={image} alt='service' width={'100%'} />
                            </Typography>
                        ))}
                    </Slider>
                </Grid>
            </Grid>
            <Container sx={{ py: 5 }}>
                <Typography variant='h3'>{data.name}</Typography>
                <Typography sx={{ my: 2 }}>{data.description} </Typography>
                <Typography>{data.callToAction} </Typography>
            </Container>
        </main>
    )
}

export default ServiceDetails