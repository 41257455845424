import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Slider from 'react-slick'

const HomeAbout = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1

  }
  const images = ['/images/banner.jpg', '/images/femme.jpg', '/images/soin-de-visage.jpg']
  return (
    <div>
      <Container sx={{ py: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h4">P-Twins Beauty Spa</Typography>
              <Typography variant="h6">Un havre de paix</Typography>
              <Typography sx={{ my: 2 }}>
                Entrez dans l'univers de P-Twins Beauty Spa, où luxe, détente et
                rajeunissement fusionnent pour transcender votre quotidien. Ici chaque
                instant est une paranthèse enchantée, une échappée belle loin du
                tumulte du monde.
              </Typography>
              <Typography sx={{ my: 2 }}>
                Asseyez-vous, respirez, évadez-vous. Laissez derrière vous l'agitation
                et plongez dans une oasis de tranquillité. Au P-Twins Beauty Spa, nous
                vous invitons à vous délecter de l'instant présent, enveloppé dans
                l'expertise de nos soins personnalisés.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Slider {...settings} >
              {
                images.map(item => (
                  <Typography>
                    <img src={item} width={'100%'} alt="" />
                  </Typography>
                ))
              }
            </Slider>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HomeAbout;
