import {
  Box,
  Container,
  Grid,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { services } from "../../data";
import ServiceModal from "../includes/Modal";

const HomeServices = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [temp, setTemp] = useState('')

  return (
    <Box>
      <Box
        sx={{
          p: { md: 8, xs: 2 },
          pb: 10,
          backgroundSize: "cover",
          color: "white",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.secondary.main, 0.7)},
         ${alpha(theme.palette.secondary.main, 0.5)}
      ), url('/images/epilation.jpg')`,
          height: "auto",
          borderWidth: 3,
          borderColor: "white",
          borderStyle: "solid",
        }}
      >
        <Container>
          <Typography textAlign={"center"} variant="h3">
            NOS SERVICES
          </Typography>
          <Typography textAlign={"center"}>
            Nous proposons une large gamme de services
          </Typography>
          <Grid sx={{ my: 5 }} container spacing={2}>
            {services.slice(0, 3).map((item) => (
              <Grid container item xs={12} md={4}>
                <Box
                  component={'a'}
                  onClick={() => {
                    handleOpen()
                    setTemp(item.id)
                  }}
                  sx={{
                    backgroundSize: "cover",
                    color: "white",
                    backgroundPosition: "center",
                    backgroundImage: `linear-gradient(
                to bottom,
                ${alpha(theme.palette.secondary.main, 0.2)},
                ${alpha(theme.palette.secondary.main, 0.1)}
                ), url(${item.image})`,
                    height: 400,
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    textAlign={"center"}
                    color={"primary"}
                    sx={{
                      py: 2,
                      backgroundColor: alpha("#000", 0.8),
                      width: "100%",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
                <ServiceModal serviceID={temp} open={open} handleClose={handleClose} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          p: { md: 8, xs: 2 },
          pb: 10,
          backgroundSize: "cover",
          color: "white",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.secondary.main, 0.7)},
         ${alpha(theme.palette.secondary.main, 0.5)}
      ), url('/images/massage.jpg')`,
          height: "auto",
          borderWidth: 3,
          borderColor: "white",
          borderStyle: "solid",
        }}
      >
        <Container>
          <Grid sx={{ my: 5 }} container spacing={2}>
            {services.slice(3, 6).map((item) => (
              <Grid container item xs={12} md={4}>
                <Box
                  component={'a'}
                  onClick={() => {
                    handleOpen()
                    setTemp(item.id)
                  }}
                  sx={{
                    backgroundSize: "cover",
                    color: "white",
                    backgroundPosition: "center",
                    backgroundImage: `linear-gradient(
                to bottom,
                ${alpha(theme.palette.secondary.main, 0.2)},
                ${alpha(theme.palette.secondary.main, 0.1)}
                ), url(${item.image})`,
                    height: 400,
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    textAlign={"center"}
                    color={"primary"}
                    sx={{
                      py: 2,
                      backgroundColor: alpha("#000", 0.8),
                      width: "100%",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
                <ServiceModal serviceID={temp} open={open} handleClose={handleClose} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          p: { md: 8, xs: 2 },
          pb: 10,
          backgroundSize: "cover",
          color: "white",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.secondary.main, 0.7)},
         ${alpha(theme.palette.secondary.main, 0.5)}
      ), url('/images/4.jpg')`,
          height: "auto",
          borderWidth: 3,
          borderColor: "white",
          borderStyle: "solid",
        }}
      >
        <Container>
          <Grid sx={{ my: 5 }} container spacing={2}>
            {services.slice(6, 9).map((item) => (
              <Grid container item xs={12} md={4}>
                <Box
                  component={'a'}
                  onClick={() => {
                    handleOpen()
                    setTemp(item.id)
                  }}
                  sx={{
                    backgroundSize: "cover",
                    color: "white",
                    backgroundPosition: "center",
                    backgroundImage: `linear-gradient(
                to bottom,
                ${alpha(theme.palette.secondary.main, 0.2)},
                ${alpha(theme.palette.secondary.main, 0.1)}
                ), url(${item.image})`,
                    height: 400,
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    textAlign={"center"}
                    color={"primary"}
                    sx={{
                      py: 2,
                      backgroundColor: alpha("#000", 0.8),
                      width: "100%",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
                <ServiceModal serviceID={temp} open={open} handleClose={handleClose} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default HomeServices;
