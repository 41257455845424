import {
  alpha,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

const HomeBanner = () => {
  const theme = useTheme();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        p: { md: 8, xs: 2 },
        pb: 10,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.secondary.main, 0.3)},
         ${alpha(theme.palette.secondary.main, 0.5)}
      ), url('/images/banner.jpg')`,
        height: 600,
      }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        item
        sx={{ color: "white" }}
        xs={12}
        md={6}
      >
        <Container>
          <Typography
            variant={"h3"}
            sx={{
              mt: 8,
              fontSize: { xs: 40, md: 60 },
              textTransform: "normal",
            }}
          >
            Votre oasis de beauté et bien-être à Kolwezi
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Button disableElevation target="_blank" component="a" href="https://wa.me/qr/O6U6CP3K6KMDP1" variant="contained">
              Réservez maintenant
            </Button>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default HomeBanner;
