import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffac01",
    },
    secondary: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "upperCase",
    },
    h3: {
      fontWeight: "600",
    },
  },
});

export default theme;
