import React from "react";
import HomeBanner from "../../components/home/Banner";
import HomeAbout from "../../components/home/About";
import HomeServices from "../../components/home/Services";
import HomeGalery from "../../components/home/Galery";
import HomeBooking from "../../components/home/Booking";

const Home = () => {
  return (
    <div>
      <HomeBanner />
      <HomeAbout />
      <HomeServices />
      <HomeGalery />
      <HomeBooking />
    </div>
  );
};

export default Home;
