import {
  Box,
  Container,
  Grid,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";
import { services } from "../../data";

const HomeGalery = () => {
  const theme = useTheme();
  return (
    <div>
      <Box
        sx={{
          p: { md: 8, xs: 2 },
          pb: 10,
          backgroundSize: "cover",
          color: "white",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.secondary.main, 0.7)},
         ${alpha(theme.palette.secondary.main, 0.5)}
      ), url('/images/banner.jpg')`,
          height: "auto",
          borderWidth: 3,
          borderColor: "white",
          borderStyle: "solid",
        }}
      >
        <Container>
          <Typography textAlign={"center"} variant="h3">
            GALERIE
          </Typography>
          <Grid sx={{ my: 5 }} container>
            {services.slice(0, 8).map(item => <Grid container item xs={12} md={3}>
              <img src={item.image} width={"100%"} />
            </Grid>)}

          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default HomeGalery;
