import Home from "./pages/home";
import ServiceDetails from "./pages/services/Details";

const routes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/services/:serviceID",
    component: <ServiceDetails />,
  },
];

export default routes;
